import { useLazyQuery } from 'data-fetching-client';
import { useSelector } from 'react-redux';
import { usePaymentFormContext } from '../context/PaymentFormContext';
import { GET_VALIDATION_ERRORS } from '../data/queries';
import { CHECKOUT_BE_VALIDATION_GATE } from '../constants/gates';
import { useCountryMetadata } from '../components/Address/FullAddress';
import { getBuyerOverridesLineItems } from '../state/BuyerOverrides/selectors';
import { useDebounce } from './useDebounce';
import { useEffect, useState, useMemo, useCallback } from 'react';
import { getIsPaymentPurchaseUrl } from '../utils/urls';
import { useLocation } from 'react-router-dom';
export function useValidationErrors() {
  const {
    pathname
  } = useLocation();
  const isPaymentPurchaseUrl = getIsPaymentPurchaseUrl(pathname);
  const [validationState, setValidationState] = useState({
    formFieldValidationErrors: [],
    subtotalsValidationErrors: [],
    isValid: false
  });
  const {
    formState,
    checkoutSession: checkoutSessionMap
  } = usePaymentFormContext();
  const checkoutSession = checkoutSessionMap.toJS();
  const isUngatedToBEValidation = checkoutSession.gates.includes(CHECKOUT_BE_VALIDATION_GATE);
  const billingAddress = formState.get('billingAddress');
  const shippingAddress = formState.get('shippingAddress');
  const countryCode = formState.getIn(['billingAddress', 'country']);
  const phoneNumbers = formState.get('phoneNumbers');
  const buyerOverridesLineItems = useSelector(getBuyerOverridesLineItems);
  const collectShippingAddress = checkoutSession.shippableCountries && !!checkoutSession.shippableCountries.length;
  const {
    countryMetadata
  } = useCountryMetadata(countryCode);
  const shouldCheckPostalCode = countryMetadata && countryMetadata.fields.some(field => field.type === 'POSTAL_CODE');
  const variables = useMemo(() => ({
    email: formState.get('email'),
    paymentMethod: formState.get('paymentMethod'),
    checkoutBuyerOverrides: {
      lineItems: buyerOverridesLineItems,
      discountCode: formState.get('discountCode') || null
    },
    billingInfo: billingAddress && {
      paymentsAddress: Object.assign({
        line1: billingAddress.get('address'),
        line2: billingAddress.get('address2')
      }, billingAddress.toJS()),
      fullName: billingAddress.get('fullName') || formState.getIn(['bankInfo', 'accountHolderName']),
      shouldCheckPostalCode
    },
    shippingInfo: collectShippingAddress && shippingAddress ? {
      paymentsAddress: Object.assign({
        line1: shippingAddress.get('address'),
        line2: shippingAddress.get('address2')
      }, shippingAddress.toJS()),
      firstName: shippingAddress.get('firstName'),
      lastName: shippingAddress.get('lastName'),
      shouldCheckPostalCode
    } : undefined,
    phoneNumbers: phoneNumbers && phoneNumbers.toJS()
  }), [billingAddress, shippingAddress, phoneNumbers, buyerOverridesLineItems, collectShippingAddress, shouldCheckPostalCode, formState]);
  const [getValidationErrors, {
    data,
    loading
  }] = useLazyQuery(GET_VALIDATION_ERRORS);
  const debouncedGetValidationErrors = useDebounce(getValidationErrors, 200);
  const flushDebouncedGetValidationErrors = useCallback(() => {
    debouncedGetValidationErrors.flush();
  }, [debouncedGetValidationErrors]);
  useEffect(() => {
    if (isUngatedToBEValidation && !formState.get('loading') && isPaymentPurchaseUrl) {
      debouncedGetValidationErrors({
        variables
      });
    }
  }, [debouncedGetValidationErrors, isUngatedToBEValidation, formState, variables, isPaymentPurchaseUrl]);
  useEffect(() => {
    if (data !== null && data !== void 0 && data.validationErrors) {
      var _data$validationError, _data$validationError2;
      setValidationState({
        formFieldValidationErrors: ((_data$validationError = data.validationErrors.formFieldValidationErrors) === null || _data$validationError === void 0 || (_data$validationError = _data$validationError[0]) === null || _data$validationError === void 0 ? void 0 : _data$validationError.errors) || [],
        subtotalsValidationErrors: ((_data$validationError2 = data.validationErrors.subtotalsValidationErrors) === null || _data$validationError2 === void 0 || (_data$validationError2 = _data$validationError2[0]) === null || _data$validationError2 === void 0 ? void 0 : _data$validationError2.errors) || [],
        isValid: data.validationErrors.isValid
      });
    }
  }, [data]);
  return {
    formFieldValidationErrors: validationState.formFieldValidationErrors,
    subtotalsValidationErrors: validationState.subtotalsValidationErrors,
    // if not a valid checkout url (i.e. iframe preview), we skip BE validation
    isValid: isPaymentPurchaseUrl ? validationState.isValid : true,
    loading,
    flushDebouncedGetValidationErrors
  };
}