import { registerQuery, registerMutation } from 'data-fetching-client';
import { fetchCheckoutSession, createCheckoutSession, createPaymentIntent } from './CheckoutSessionApi';
import { fetchBuyerOverridesPreviewSubtotals, fetchBuyerOverridesSubtotals } from './BuyerOverridesApi';
import { fetchCountryMetadata } from './CountryMetadataApi';
import { fetchTransactionLimits } from './LimitsApi';
import { fetchMerchantPolicySettings } from './MerchantPolicySettingsApi';
import { fetchValidationErrors } from './ValidateApi';
import { calculateTax } from './CalculateTaxApi';
export const GET_CHECKOUT_SESSION = registerQuery({
  fieldName: 'checkoutSession',
  fetcher: fetchCheckoutSession
});
export const CREATE_CHECKOUT_SESSION = registerMutation({
  fieldName: 'checkoutSession',
  fetcher: createCheckoutSession
});
export const CREATE_PAYMENT_INTENT = registerMutation({
  fieldName: 'paymentIntentDetails',
  args: ['data'],
  fetcher: createPaymentIntent
});
export const GET_VALIDATION_ERRORS = registerQuery({
  fieldName: 'validationErrors',
  args: ['checkoutBuyerOverrides', 'paymentMethod', 'email', 'billingInfo', 'shippingInfo', 'phoneNumbers'],
  fetcher: fetchValidationErrors
});
export const GET_COUNTRY_METADATA = registerQuery({
  fieldName: 'countryMetadata',
  args: ['countryCode'],
  fetcher: fetchCountryMetadata
});
export const GET_TRANSACTION_LIMITS = registerQuery({
  fieldName: 'limits',
  fetcher: fetchTransactionLimits
});
export const CREATE_BUYER_OVERRIDES_SUBTOTALS = registerQuery({
  fieldName: 'subtotals',
  args: ['buyerOverrides', 'paymentSessionId', 'taxCalculationId'],
  fetcher: fetchBuyerOverridesSubtotals
});
export const CREATE_BUYER_OVERRIDES_PREVIEW_SUBTOTALS = registerQuery({
  fieldName: 'subtotals',
  args: ['buyerOverrides'],
  fetcher: fetchBuyerOverridesPreviewSubtotals
});
export const GET_POLICY_SETTINGS = registerQuery({
  fieldName: 'policySettings',
  fetcher: fetchMerchantPolicySettings
});
export const CALCULATE_TAX = registerQuery({
  fieldName: 'calculateTax',
  args: ['checkoutSessionId', 'data'],
  fetcher: calculateTax
});