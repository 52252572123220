import noAuthClient from 'hub-http/clients/noAuthApiClient';
import { catchAndRethrowSensitiveNetworkError
// @ts-expect-error Untyped module
} from 'crm_data/bCommerce/monitoring/errorUtils';
// errors come from here https://git.hubteam.com/HubSpotProtected/CommerceSalesTaxCalculations/blob/master/CommerceSalesTaxCalculationsModels/src/main/java/com/hubspot/commerce/sales/tax/models/calculations/errors/AbstractTaxCalculationCreateError.java#L48
export const TAX_CALCULATION_CREATE_ERROR_SUBCATEGORIES = {
  INVALID_CUSTOMER_TAX_LOCATION: 'TaxCalculationCreateError.INVALID_CUSTOMER_TAX_LOCATION',
  INVALID_SHIPPING_ADDRESS: 'TaxCalculationCreateError.INVALID_SHIPPING_ADDRESS',
  INVALID_TAX_LOCATION: 'TaxCalculationCreateError.INVALID_TAX_LOCATION',
  MUST_PROVIDE_POSTAL_CODE: 'TaxCalculationCreateError.MUST_PROVIDE_POSTAL_CODE',
  // TODO: replace with the real error once the backend is updated
  TOTAL_AFTER_TAX_OVER_LIMIT: 'TaxCalculationCreateError.TOTAL_AFTER_TAX_OVER_LIMIT'
};
export const calculateTax = async ({
  checkoutSessionId,
  data
}) => {
  try {
    const {
      taxCalculationId
    } = await noAuthClient.post(`/payments-checkout/v1/checkout-sessions/${checkoutSessionId}/calculateTax`, {
      data
    });
    return {
      taxCalculationId
    };
  } catch (error) {
    catchAndRethrowSensitiveNetworkError(error);
    throw error;
  }
};