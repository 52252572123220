import { createSelector, createSlice } from '@reduxjs/toolkit';
const initialState = {
  loading: false,
  taxCalculationId: null
};
const getCalculateTaxState = state => state.calculateTax;
export const getCalculateTaxLoading = createSelector([getCalculateTaxState], state => state.loading);
export const getTaxCalculationId = createSelector([getCalculateTaxState], state => state.taxCalculationId);
const calculateTaxSlice = createSlice({
  name: 'calculateTax',
  initialState,
  reducers: {
    setCalculateTaxLoading: (state, action) => {
      state.loading = action.payload;
    },
    setTaxCalculationId: (state, action) => {
      state.taxCalculationId = action.payload;
    }
  }
});
export const {
  setCalculateTaxLoading,
  setTaxCalculationId
} = calculateTaxSlice.actions;
export default calculateTaxSlice.reducer;