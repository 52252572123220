// @ts-expect-error Untyped module
import EmailAddressPattern from 'PatternValidationJS/patterns/EmailAddress';
import { FORM_FIELD_FIELD_TYPES } from '../constants/FormFields';
import { isFutureBilledCheckoutLineItems, isFormFieldInputImmutableStringList, isFutureBilledCheckoutLineItem, isOptional } from '../types/utils/typeguards';
import { getIsAnyRecurring, getRecurringSubtotal } from './recurring';
import { ACH_METHOD, CARD_METHOD } from '../constants/PaymentElement';
import { isCardOrWallet } from './paymentMethods';
export const getHasOpenAmountLineItem = lineItems => {
  return lineItems.some(lineItem => !!lineItem.getIn(['customConfiguration', 'hasEditablePrice']));
};
export const getIsMaximumValid = (checkoutSession, paymentMethod, limits) => {
  const maximumAmount = limits && paymentMethod && limits.maximumAmountForPaymentMethod[paymentMethod];
  const hasOpenAmountLineItem = getHasOpenAmountLineItem(checkoutSession.get('lineItems'));
  return hasOpenAmountLineItem ? maximumAmount && checkoutSession.getIn(['subtotalsPublicResponse', 'paymentAmount']) < maximumAmount : true;
};
export const getIsZeroCheckoutValid = (paymentAmount, lineItems) => paymentAmount === 0 && (isFutureBilledCheckoutLineItems(lineItems) || lineItems.some(isFutureBilledCheckoutLineItem) &&
// ensures that checkouts with future + optional items are valid, but only if a future item is present
lineItems.every(item => isFutureBilledCheckoutLineItem(item) || isOptional(item)));
export const getAreAcknowledgementsValid = acknowledgements => acknowledgements.every(([acknowledgementRequired, isAcknowledged]) => !acknowledgementRequired || isAcknowledged);
export const getEmailErrorMessage = email => email ? !EmailAddressPattern.test(email) ? 'payments.contactInfo.invalidEmailAddress' : null : 'payments.inputError.emptyEmailAddress';
export const getIsShippingAddressValid = (shippingAddress, checkoutSession, fieldsIncludePostalCode) => checkoutSession.get('shippableCountries').size > 0 ? !!(shippingAddress.get('firstName') && shippingAddress.get('lastName') && shippingAddress.get('address') && shippingAddress.get('city') && shippingAddress.get('state') && (fieldsIncludePostalCode ? shippingAddress.get('postalCode') : true) && shippingAddress.get('country')) : true;
export const getIsBillingAddressValid = (billingAddress, checkoutSession, stripePaymentMethod, fieldsIncludePostalCode) => checkoutSession.get('collectFullBillingAddress') ? !!(billingAddress.get('fullName') && billingAddress.get('address') && (fieldsIncludePostalCode ? billingAddress.get('postalCode') : true) && billingAddress.get('country') && billingAddress.get('city') && billingAddress.get('state')) : stripePaymentMethod === CARD_METHOD ? !!billingAddress.get('fullName') : true;
export const getIsBankInfoValid = bankInfo => !!bankInfo.get('accountHolderName');
export const getIsCustomFormFieldsValid = (formState, checkoutSession) => checkoutSession.get('formFields').filter(formField => formField.get('required') && formField.get('name') !== 'email').every(formField => {
  const fieldType = formField.get('fieldType');
  const userInput = formState.getIn(['customData', formField.get('name')]);
  switch (fieldType) {
    case FORM_FIELD_FIELD_TYPES.NUMBER:
    case FORM_FIELD_FIELD_TYPES.DATE:
      return typeof userInput === 'number' && !isNaN(userInput);
    case FORM_FIELD_FIELD_TYPES.CHECKBOX:
      return !!userInput && isFormFieldInputImmutableStringList(userInput) && userInput.size > 0;
    default:
      return Boolean(userInput);
  }
});
export const getIsSubtotalValid = (paymentAmount, lineItems, minimumAmount) => {
  return minimumAmount && paymentAmount >= minimumAmount || isFutureBilledCheckoutLineItems(lineItems) && paymentAmount === 0;
};
export const getIsPartialPaymentAmountValid = (checkoutSession, minimumAmount) => {
  const balanceDue = checkoutSession.getIn(['partialPaymentsInfo', 'balanceDue']);
  const paymentAmount = checkoutSession.getIn(['subtotalsPublicResponse', 'paymentAmount']);
  const amountRemaining = checkoutSession.getIn(['subtotalsPublicResponse', 'partialPaymentsSubtotals', 'amountRemaining']);
  if (typeof balanceDue === 'number' && typeof amountRemaining === 'number' && typeof minimumAmount === 'number' && !!paymentAmount) {
    return paymentAmount <= balanceDue && (amountRemaining === 0 || amountRemaining >= minimumAmount);
  }
  return true;
};
export const getIsRecurringSubtotalValid = (checkoutSession, selectedOptionalLineItems, minimumAmount) => {
  if (!getIsAnyRecurring(checkoutSession.get('lineItems').toJS(), selectedOptionalLineItems)) {
    return true;
  }
  return minimumAmount && getRecurringSubtotal(checkoutSession.get('lineItems')) >= minimumAmount;
};
export const getAreCheckoutSessionSubtotalsValid = (checkoutSession, selectedOptionalLineItems, paymentMethod, limits) => {
  const minimumAmount = limits && limits.minimumAmountForPaymentMethod[paymentMethod];
  return getIsSubtotalValid(checkoutSession.getIn(['subtotalsPublicResponse', 'paymentAmount']), checkoutSession.get('lineItems').toJS(), minimumAmount) && getIsPartialPaymentAmountValid(checkoutSession, minimumAmount) && getIsRecurringSubtotalValid(checkoutSession, selectedOptionalLineItems, minimumAmount);
};
export const getCanSubmitPaymentV2 = (formState, checkoutSession, isValid, merchantTermsOfServiceAcknowledgementRequired = false, collectPaymentMethodOnFileAcknowledgementRequired = false) => Boolean(isValid && getAreAcknowledgementsValid([[merchantTermsOfServiceAcknowledgementRequired, formState.get('merchantTermsOfServiceAcknowledged')], [collectPaymentMethodOnFileAcknowledgementRequired, formState.get('collectPaymentMethodOnFileAcknowledged')]]) && getIsCustomFormFieldsValid(formState, checkoutSession) && !formState.get('stripeValidationError'));

// TODO: remove this and related helpers once Checkout BE Validation is rolled out
export const getCanSubmitPayment = (formState, checkoutSession, merchantTermsOfServiceAcknowledgementRequired = false, selectedOptionalLineItems, limits, stripePaymentMethod, fieldsIncludePostalCode, collectPaymentMethodOnFileAcknowledgementRequired = false) => !!(formState.get('email') && !formState.get('stripeValidationError') && !getEmailErrorMessage(formState.get('email')) && getIsCustomFormFieldsValid(formState, checkoutSession) && getIsShippingAddressValid(formState.get('shippingAddress'), checkoutSession, fieldsIncludePostalCode) && (stripePaymentMethod === ACH_METHOD ? getIsBankInfoValid(formState.get('bankInfo')) : isCardOrWallet(stripePaymentMethod) ? getIsBillingAddressValid(formState.get('billingAddress'), checkoutSession, stripePaymentMethod, fieldsIncludePostalCode) : true) && getAreCheckoutSessionSubtotalsValid(checkoutSession, selectedOptionalLineItems, formState.get('paymentMethod'), limits) && getIsMaximumValid(checkoutSession, formState.get('paymentMethod'), limits) && getAreAcknowledgementsValid([[merchantTermsOfServiceAcknowledgementRequired, formState.get('merchantTermsOfServiceAcknowledged')], [collectPaymentMethodOnFileAcknowledgementRequired, formState.get('collectPaymentMethodOnFileAcknowledged')]]));