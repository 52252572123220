import { OLAF, OBSIDIAN } from 'HubStyleTokens/colors';
import { getRGB, canReadText as canReadTextUtility } from 'foundations-components/transitional/utils/color';
const CONTRAST_THRESHOLD_OVERRIDE = 2.0;
const WHITENESS_THRESHOLD = 0.95;
export function canReadText(backgroundHex, textColor = OLAF) {
  return canReadTextUtility(backgroundHex, textColor, CONTRAST_THRESHOLD_OVERRIDE);
}
export function getTextColor(primaryColor) {
  return canReadText(primaryColor, OLAF) ? OLAF : OBSIDIAN;
}
export function getTextColorTrellis(primaryColor) {
  return canReadText(primaryColor, OLAF) ? 'text-primary-on-fill-default' : 'text-primary-default';
}
export function getWhiteness(hex) {
  const {
    r: rRaw,
    g: gRaw,
    b: bRaw
  } = getRGB(hex);
  const r = rRaw / 255.0;
  const g = gRaw / 255.0;
  const b = bRaw / 255.0;
  const cmin = Math.min(r, g, b);
  const cmax = Math.max(r, g, b);
  const whiteness = (cmin + cmax) / 2;
  return whiteness >= WHITENESS_THRESHOLD;
}