import { Component } from 'react';
import { equals, fromString, getBoundedDate, isValid, nextDay, prevDay, toFormattedString } from '../../utils/SimpleDate';
import { syntheticEvent } from '../../utils/SyntheticEvent';
import { callIfPossible } from '../../utils/functions';
export const getPageFromDate = date => {
  if (!isValid(date)) return null;
  return {
    year: date === null || date === void 0 ? void 0 : date.year,
    month: date === null || date === void 0 ? void 0 : date.month
  };
};
class AbstractCalendar extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = evt => {
      const {
        format = 'L',
        onChange,
        value
      } = this.props;
      this.setState({
        inputValue: evt.target.value
      });

      // Update date value as the user types
      const userInput = evt.target.value.trim();
      if (userInput === '') {
        callIfPossible(onChange, syntheticEvent(null));
      } else {
        const userInputDate = fromString(userInput, format);
        if (userInputDate !== null && !equals(userInputDate, value)) {
          this.updateDate(userInputDate);
        }
      }
    };
    this.handleKeyDown = evt => {
      const {
        onOpenChange,
        open,
        originDate,
        today,
        value
      } = this.props;
      const {
        key
      } = evt;
      clearTimeout(this._keyUpTimeout);
      this._pressedKey = key;

      // The "keyboard cursor" is implicitly on the current selection, or today's date if clear.
      const computedCurrentDate = isValid(value) ? value : originDate || today;
      if (key === 'ArrowUp') {
        evt.preventDefault();
        const newValue = nextDay(computedCurrentDate);
        this.updateDate(newValue);
      } else if (key === 'ArrowDown') {
        evt.preventDefault();
        const newValue = prevDay(computedCurrentDate);
        this.updateDate(newValue);
      } else if (key === 'Escape') {
        if (!open || !onOpenChange) return;
        evt.preventDefault();
        evt.stopPropagation();
        onOpenChange(syntheticEvent(false));
      }
    };
    this.handleKeyUp = () => {
      // The delay here should ensure that onKeyUp resolves after onChange, even under IE11 (#7331).
      clearTimeout(this._keyUpTimeout);
      this._keyUpTimeout = setTimeout(() => {
        this._pressedKey = null;
      }, 0);
    };
    this.handleBlur = () => {
      const {
        format = 'L',
        onFocusedValueChange,
        value
      } = this.props;
      this.updateInput(value, format);
      this._pressedKey = null;
      callIfPossible(onFocusedValueChange, syntheticEvent(null));
    };
    this.state = {
      inputValue: toFormattedString(props.value, props.format)
    };
    this._keyUpTimeout = 0;
    this._pressedKey = null;
  }
  componentDidUpdate(prevProps) {
    const {
      calendarPage,
      onCalendarPageChange,
      onOpenChange,
      open,
      value,
      format = 'L'
    } = prevProps;
    if (!equals(value, this.props.value)) {
      // Flip to the calendar page that includes the selected date
      const newCalendarPage = getPageFromDate(this.props.value);
      if (newCalendarPage && !equals(newCalendarPage, calendarPage)) {
        callIfPossible(onCalendarPageChange, syntheticEvent(newCalendarPage));
      }

      // `pressedKey` tells us whether this change was caused by the mouse or the keyboard
      const shouldClosePicker = this._pressedKey === null;
      const shouldUpdateInput = this._pressedKey === null || this._pressedKey === 'ArrowUp' || this._pressedKey === 'ArrowDown';
      if (shouldClosePicker && open && onOpenChange) {
        onOpenChange(syntheticEvent(false));
      }
      if (shouldUpdateInput) {
        this.updateInput(this.props.value, this.props.format);
      }
    } else if (format !== this.props.format) {
      this.updateInput(this.props.value, this.props.format);
    }
  }
  componentWillUnmount() {
    clearTimeout(this._keyUpTimeout);
  }
  updateInput(value, format) {
    const {
      inputValue
    } = this.state;
    const formattedDate = toFormattedString(value, format);
    if (formattedDate !== inputValue) {
      this.setState({
        inputValue: formattedDate
      });
    }
  }
  updateDate(newValue) {
    const {
      calendarPage,
      disabledValues,
      max,
      maxValue,
      min,
      minValue,
      onCalendarPageChange,
      onChange,
      onFocusedValueChange,
      onOpenChange
    } = this.props;
    if (!calendarPage || (newValue === null || newValue === void 0 ? void 0 : newValue.month) !== calendarPage.month) {
      callIfPossible(onCalendarPageChange, syntheticEvent({
        year: newValue === null || newValue === void 0 ? void 0 : newValue.year,
        month: newValue === null || newValue === void 0 ? void 0 : newValue.month
      }));
    }
    if (!equals(newValue, getBoundedDate(newValue, min || minValue, max || maxValue)) || disabledValues && disabledValues.some(disabledValue => equals(newValue, disabledValue))) {
      // Show tooltip on disabled date
      callIfPossible(onFocusedValueChange, syntheticEvent(newValue));
      if (onOpenChange) {
        onOpenChange(syntheticEvent(true));
      }
      return;
    }
    callIfPossible(onFocusedValueChange, syntheticEvent(null));
    callIfPossible(onChange, syntheticEvent(newValue));
  }
  render() {
    const {
      children
    } = this.props;
    const {
      inputValue
    } = this.state;
    return children({
      onBlur: this.handleBlur,
      onInputChange: this.handleInputChange,
      onKeyDown: this.handleKeyDown,
      onKeyUp: this.handleKeyUp,
      inputValue
    });
  }
}
export default AbstractCalendar;