import { usePaymentFormContext } from '../context/PaymentFormContext';
import { useSubtotals } from '../context/SubtotalsProvider';
import { isFutureBilledSubtotal } from '../types/utils/typeguards';
import { useMerchantPolicySettings } from './useMerchantPolicySettings';
const getPrefix = ({
  recurringSubtotals,
  policySettings,
  termsOfServiceUrl
}) => {
  const hasFutureCharges = recurringSubtotals.some(subtotal => isFutureBilledSubtotal(subtotal));
  const hasMultipleRecurringSubscriptions = recurringSubtotals.length > 1;
  // These conditions cover all the scenarios for PLs and Quotes. The policy settings can be configured in the Payments Checkout Settings Taxes & Policies tab.
  if (!policySettings.customPolicyEnabled) {
    // Renders the terms of service message in PLs and Quotes when there are multiple subscriptions (eg 1 monthly + 1 yearly).
    if (hasMultipleRecurringSubscriptions) {
      if (hasFutureCharges) {
        return 'payments.merchantTermsOfService.policyDisabled.withFutureStartDate';
      }
      return 'payments.merchantTermsOfService.policyDisabled.withMultipleRecurringSubs';
    } else {
      // This applies only to Payment links because Quotes has a default value for the termsOfServiceUrl which is the public Quote URL so the terms of service message is always displayed.
      if (!termsOfServiceUrl) {
        return null;
      }
    }
  }
  if (hasMultipleRecurringSubscriptions) {
    if (hasFutureCharges) {
      return 'payments.merchantTermsOfService.futureStartDate';
    }
    return 'payments.merchantTermsOfService.multipleRecurringSubs';
  } else {
    // For one-time LIs and single subscriptions(eg 1 one-time + 1 monthly , 1 one-time + 1 future charge yearly)
    return 'payments.merchantTermsOfService';
  }
};
const getSuffix = ({
  displayAcknowledgementCheckbox,
  termsOfServiceUrl,
  companyName,
  policySettings
}) => {
  // Without a value for the termsOfServiceUrl we show the terms of service message without linking the termsOfServiceUrl.
  // eg. By submitting this transaction, you agree to {{companyName}}'s terms of service and to have your payment method charged.
  // When there is a value for the termsOfServiceUrl there is a link in the message that takes the user to the termsOfServiceUrl.
  if (displayAcknowledgementCheckbox) {
    if (termsOfServiceUrl) {
      return companyName ? 'acknowledgementRequiredWithLink_jsx' : 'noCompanyAcknowledgementRequiredWithLink_jsx';
    } else {
      return companyName ? 'acknowledgementRequiredWithoutLink' : 'noCompanyAcknowledgementRequiredWithoutLink';
    }
  } else if (companyName || !policySettings.customPolicyEnabled) {
    return termsOfServiceUrl ? 'withLink_jsx' : 'withoutLink';
  }
  return termsOfServiceUrl ? 'noCompanyWithLink_jsx' : 'noCompanyWithoutLink';
};

/**
 * This hook returns the translation key for the terms of service message.
 * For every main scenario getPrefix returns the first half of the translation key. For most of the main scenarios
 * there are four secondary scenarios returned by getSuffix which makes up the second half of the key.
 * Based on this key MaybeTermsOfService renders the appropriate message.
 * @returns message key
 */
export function useMerchantTermsOfServiceMessage() {
  const {
    recurringSubtotals = []
  } = useSubtotals();
  const {
    checkoutSession: checkoutSessionMap
  } = usePaymentFormContext();
  const checkoutSession = checkoutSessionMap.toJS();
  const {
    policySettings,
    displayAcknowledgementCheckbox
  } = useMerchantPolicySettings(checkoutSession);
  const termsOfServiceUrl = checkoutSession.termsOfServiceUrl;
  const companyName = checkoutSession.branding && checkoutSession.branding.companyName;
  if (!policySettings) {
    return null;
  }
  const messageKeyPrefix = getPrefix({
    recurringSubtotals,
    policySettings,
    termsOfServiceUrl
  });
  const messageKeySuffix = getSuffix({
    displayAcknowledgementCheckbox,
    termsOfServiceUrl,
    companyName,
    policySettings
  });
  if (!messageKeyPrefix || !messageKeySuffix) {
    return null;
  }
  return `${messageKeyPrefix}.${messageKeySuffix}`;
}