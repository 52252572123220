import I18n from 'I18n';
import { fetchBuyerOverridesPreviewSubtotals, fetchBuyerOverridesSubtotals } from '../../data/BuyerOverridesApi';
import { setDiscountCodeErrorMessage, setDiscountAmount, setIsDiscountPending } from '../../state/DiscountCode/discountCodeSlice';
import { OPEN } from 'customer-data-objects/ticket/TicketStageStatusOptions';
import updateCheckoutSessionSubtotals from '../../utils/updateCheckoutSessionSubtotals';
// @ts-expect-error Untyped module
import getNativeCurrencySymbol from 'I18n/utils/getNativeCurrencySymbol';
import { setErrorMessages } from '../../state/errorMessagesSlice';
const handleBelowMinimumTotal = ({
  reduxDispatch,
  nativeCurrencySymbol,
  minimumAmount
}) => {
  reduxDispatch(setIsDiscountPending(false));
  reduxDispatch(setDiscountCodeErrorMessage(I18n.text('payments.notSufficientTotalMessage', {
    currencySymbol: nativeCurrencySymbol,
    stripeMinimumRequired: minimumAmount && minimumAmount.toFixed(2)
  })));
};
const parseDiscountCodeApplyResponseData = ({
  data,
  status,
  minimumAmount,
  client,
  reduxDispatch,
  dispatch,
  currencyCode
}) => {
  if (status === OPEN) {
    if (minimumAmount && data.firstPaymentTotal < minimumAmount) {
      handleBelowMinimumTotal({
        reduxDispatch,
        nativeCurrencySymbol: getNativeCurrencySymbol(currencyCode),
        minimumAmount
      });
    } else {
      if (data.discountCodeResponse) {
        reduxDispatch(setDiscountCodeErrorMessage(''));
        reduxDispatch(setErrorMessages({
          invalidDiscountCode: null
        }));
        reduxDispatch(setDiscountAmount({
          discountedValue: data.oneTimeDiscountAmount ? data.oneTimeDiscountAmount : 0,
          discountedPercentage: data.discountCodeResponse.checkoutSessionDiscount.valueType === 'PERCENT' ? data.discountCodeResponse.checkoutSessionDiscount.value : 0
        }));
        dispatch({
          type: 'setIsDiscountCodeApplied',
          data: true
        });
        reduxDispatch(setIsDiscountPending(false));
        updateCheckoutSessionSubtotals(client, data);
      }
    }
  }
};
export function applyDiscount({
  checkoutSession,
  dispatch,
  reduxDispatch,
  isIframePreviewUrl,
  buyerOverrides,
  isDiscountRemoved,
  minimumAmount,
  client
}) {
  const {
    currencyCode,
    status,
    id: paymentSessionId,
    lineItems,
    portalId,
    adjustments
  } = checkoutSession;
  if (isIframePreviewUrl) {
    fetchBuyerOverridesPreviewSubtotals({
      buyerOverrides,
      currencyCode,
      lineItems,
      portalId,
      adjustments
    }).then(data => {
      if (isDiscountRemoved) {
        updateCheckoutSessionSubtotals(client, data);
        dispatch({
          type: 'setDiscountCode',
          data: ''
        });
      } else {
        parseDiscountCodeApplyResponseData({
          data,
          status,
          minimumAmount,
          client,
          dispatch,
          reduxDispatch,
          currencyCode
        });
      }
    }).catch(() => {
      reduxDispatch(setIsDiscountPending(false));
      reduxDispatch(setDiscountCodeErrorMessage(I18n.text('payments.invalidDiscountCodeMessage')));
    });
  } else {
    fetchBuyerOverridesSubtotals({
      buyerOverrides,
      paymentSessionId
    }).then(data => {
      if (isDiscountRemoved) {
        updateCheckoutSessionSubtotals(client, data);
        dispatch({
          type: 'setDiscountCode',
          data: ''
        });
      } else {
        parseDiscountCodeApplyResponseData({
          data,
          status,
          minimumAmount,
          client,
          dispatch,
          reduxDispatch,
          currencyCode
        });
      }
    }).catch(() => {
      reduxDispatch(setIsDiscountPending(false));
      reduxDispatch(setDiscountCodeErrorMessage(I18n.text('payments.invalidDiscountCodeMessage')));
      reduxDispatch(setErrorMessages({
        invalidDiscountCode: 'payments.invalidDiscountCodeMessage'
      }));
    });
  }
}